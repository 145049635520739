import React from "react";

import "./App.scss";
import Introduction from "./components/Introduction";

const App = () => {
  return (
    <Introduction />
  )
};

export default App;
